import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import { useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import reference from "../data/reference";

dayjs.extend(duration)

const checkrgx = /^\d\d:\d\d$/;

const originalRows = {
  Dimanche: { debut: "", fin: "", tdtp: "00:00", tdp: "00:00", tdte: "00:00" },
  Lundi: { debut: "", fin: "", tdtp: "00:00", tdp: "00:00", tdte: "00:00" },
  Mardi: { debut: "", fin: "", tdtp: "00:00", tdp: "00:00", tdte: "00:00" },
  Mercredi: { debut: "", fin: "", tdtp: "00:00", tdp: "00:00", tdte: "00:00" },
  Jeudi: { debut: "", fin: "", tdtp: "00:00", tdp: "00:00", tdte: "00:00" },
  Vendredi: { debut: "", fin: "", tdtp: "00:00", tdp: "00:00", tdte: "00:00" },
  Samedi: { debut: "", fin: "", tdtp: "00:00", tdp: "00:00", tdte: "00:00" },
}

function calculateTDTP(rows, travelTime) {
  // Calculate Temps de travail planifiees
  const newRows = { ...rows };

  Object.keys(newRows).forEach(day => {
    const data = rows[day];

    if (
      travelTime &&
      checkrgx.test(travelTime) &&
      data.debut &&
      data.fin &&
      data.debut !== data.fin &&
      checkrgx.test(data.debut) &&
      checkrgx.test(data.fin)
    ) {
      const timeBetweenDebAnFin = dayjs.duration(dayjs(`2020-01-01T${data.fin}:00.000Z`).diff(dayjs(`2020-01-01T${data.debut}:00.000Z`)));
      const splitTravelTime = travelTime.split(":");
      const temps = timeBetweenDebAnFin.add({ hours: splitTravelTime[0], minutes: splitTravelTime[1] });
      const formattedtemps = temps.format("HH:mm");
      newRows[day].tdtp = formattedtemps;
    } else {
      newRows[day].tdtp = "00:00";
    }
  });

  return newRows;
}

function calculateTDP(rows) {
  // calculate Temps de pauses
  const newRows = { ...rows };

  Object.keys(newRows).forEach(day => {
    const { tdtp } = rows[day];

    if (tdtp === "00:00") {
      newRows[day].tdp = "00:00";
    } else {
      let tempsDePauses = "00:00";

      let lowestMinutes = 1000;

      reference.forEach(ref => {
        const minutes = dayjs.duration(dayjs(`2020-01-01T${ref.heures}:00.000Z`).diff(dayjs(`2020-01-01T${tdtp}:00.000Z`))).asMinutes();

        if (minutes < lowestMinutes && minutes >= 0) {
          tempsDePauses = ref.pauses;
          lowestMinutes = minutes;
        }
      })

      newRows[day].tdp = tempsDePauses;
    }
  });

  return newRows;
}

function calculateTDTE(rows) {
  // Calculate Temps de travail effectif
  const newRows = { ...rows };

  Object.keys(newRows).forEach(day => {
    const { tdp, tdtp } = rows[day];

    if (tdp === "00:00") {
      newRows[day].tdte = "00:00";
    } else {
      const splitpauses = tdp.split(':');
      const splitTDTP = tdtp.split(':');
      const diff = dayjs.duration({
        hours: splitTDTP[0],
        minutes: splitTDTP[1]
      }).subtract(dayjs.duration({ hours: splitpauses[0], minutes: splitpauses[1] }));
      newRows[day].tdte = diff.format("HH:mm");
    }
  })

  return newRows;
}

function calculateTotal(rows, setTotal, cxString, siString) {
  let newTotal = dayjs.duration({ hours: 0, minutes: 0 });

  Object.keys(rows).forEach(day => {
    const { tdte } = rows[day];
    const splitTDTE = tdte.split(":");
    newTotal = newTotal.add(dayjs.duration({ hours: splitTDTE[0], minutes: splitTDTE[1] }));
  });

  const splitCX = cxString.split(":");
  newTotal = newTotal.add(dayjs.duration({ hours: splitCX[0], minutes: splitCX[1] }));

  const splitSI = siString.split(":")
  newTotal = newTotal.add(dayjs.duration({ hours: splitSI[0], minutes: splitCX[1] }));

  const days = newTotal.format("DD");

  const newTotalString = newTotal.format("HH:mm");

  const newTotalSplit = newTotalString.split(":");

  newTotalSplit[0] = parseInt(newTotalSplit[0], 10) + parseInt(days, 10) * 24;

  setTotal(newTotalSplit.join(":"));
}

export function Compteur() {
  const [travelTime, setTravelTime] = useState("");
  const [rows, setRows] = useState(originalRows);
  const [total, setTotal] = useState("00:00");
  const [CX, setCX] = useState(0);
  const [cxString, setCxString] = useState("00:00");
  const [SI, setSI] = useState(0);
  const [siString, setSiString] = useState("00:00");

  function handleTravelTimeChange(e) {
    setTravelTime(e.target.value);
  }

  function handleDataChange(day, debOrFin, data) {
    setRows(oldRows => {
      const newRows = { ...oldRows };

      newRows[day][debOrFin] = data;

      const newRowsWithTDTP = calculateTDTP(newRows, travelTime);

      const newRowsWithTDP = calculateTDP(newRowsWithTDTP);

      const newRowsWithTDTE = calculateTDTE(newRowsWithTDP);

      return newRowsWithTDTE;
    });
  }

  useEffect(() => {
    calculateTotal(rows, setTotal, cxString, siString);
  }, [rows, cxString, siString]);

  useEffect(() => {
    setCxString(`${String(CX * 7).padStart(2, '0')}:00`)
  }, [CX]);

  useEffect(() => {
    setSiString(`${String(SI * 7).padStart(2, '0')}:00`)
  }, [SI]);


  return (
    <form>
      <TextField
        fullWidth
        label="Temps du trajet"
        placeholder="00:00"
        value={travelTime}
        onChange={handleTravelTimeChange}
        error={!!travelTime && !checkrgx.test(travelTime)}
      />
      <h3>Horaires de badgeage</h3>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Jour</TableCell>
            <TableCell>Debut</TableCell>
            <TableCell>Fin</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.keys(rows).map(day => {
              const data = rows[day];

              return (
                <TableRow key={`horaires-${day}`}>
                  <TableCell>{day}</TableCell>
                  <TableCell>
                    <TextField
                      fullwidth
                      placeholder="00:00"
                      value={data.debut}
                      onChange={e => handleDataChange(day, 'debut', e.target.value)}
                      error={!!data.debut && !checkrgx.test(data.debut)}
                      disabled={!travelTime || !checkrgx.test(travelTime)}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullwidth
                      placeholder="00:00"
                      value={data.fin}
                      onChange={e => handleDataChange(day, 'fin', e.target.value)}
                      error={!!data.fin && !checkrgx.test(data.fin)}
                      disabled={!travelTime || !checkrgx.test(travelTime)}
                    />
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Jour</TableCell>
            <TableCell>Temps de travail planifiées</TableCell>
            <TableCell>Temps de pauses</TableCell>
            <TableCell>Temps de travail effectif</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.keys(rows).map(day => {
              const data = rows[day];

              return (
                <TableRow key={`results-${day}`}>
                  <TableCell>{day}</TableCell>
                  <TableCell>{data.tdtp}</TableCell>
                  <TableCell>{data.tdp}</TableCell>
                  <TableCell>{data.tdte}</TableCell>
                </TableRow>
              )
            })
          }
          <TableRow>
            <TableCell/>
            <TableCell/>
            <TableCell><b>Total:</b></TableCell>
            <TableCell style={{ backgroundColor: total === "35:00" ? '#31C57F' : '#F67272' }}>{total}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table style={{ backgroundColor: "#FFE699" }}>
        <TableHead>
          <TableRow>
            <TableCell/>
            <TableCell>QT de CX</TableCell>
            <TableCell>En heures</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell><b>CX</b> pris cette semaine</TableCell>
            <TableCell><TextField type="number" value={CX} onChange={e => setCX(e.target.value)}/></TableCell>
            <TableCell>{cxString}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table style={{ backgroundColor: "#F7B997" }}>
        <TableHead>
          <TableRow>
            <TableCell/>
            <TableCell>QT de <b>SI</b></TableCell>
            <TableCell>en heures</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Jours en <b>SI</b> cette semaine</TableCell>
            <TableCell><TextField typ="number" value={SI} onChange={e => setSI(e.target.value)}/></TableCell>
            <TableCell>{siString}</TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </form>
  );
}
