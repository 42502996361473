import { Compteur } from "./views/compteur";
import { Reference } from "./views/reference";
import { AppBar, Box, CssBaseline, Tab, Tabs } from "@material-ui/core";
import {useState} from "react";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function App() {
  const [value, setValue] = useState(0);

  function handleChange(e, newValue) {
    setValue(newValue)
  }

  return (
    <div>
      <CssBaseline />
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Compteur" />
          <Tab label="Tableau de référence" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Compteur />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Reference />
      </TabPanel>
    </div>
  );
}

export default App;
