const reference = [
  { pauses: "00:15", heures: "04:30", effectif: "04:15", color: "#B8D8A4" },
  { pauses: "00:15", heures: "04:45", effectif: "04:30", color: "#B8D8A4" },
  { pauses: "00:15", heures: "05:00", effectif: "04:45", color: "#B8D8A4" },
  { pauses: "00:15", heures: "05:15", effectif: "05:00", color: "#B8D8A4" },
  { effectif: "05:15", pauses: "00:15", heures: "05:30", color: "#B8D8A4" },
  { effectif: "05:30", pauses: "00:15", heures: "05:45", color: "#B8D8A4" },
  { effectif: "05:30", pauses: "00:30", heures: "06:00", color: "#FFE18B" },
  { effectif: "05:45", pauses: "00:30", heures: "06:15", color: "#FFE18B" },
  { effectif: "05:45", pauses: "00:45", heures: "06:30", color: "#F7B997" },
  { effectif: "06:00", pauses: "00:45", heures: "06:45", color: "#F7B997" },
  { effectif: "06:15", pauses: "00:45", heures: "07:00", color: "#F7B997" },
  { effectif: "06:30", pauses: "00:45", heures: "07:15", color: "#F7B997" },
  { effectif: "06:30", pauses: "01:00", heures: "07:30", color: "#AFBFE3" },
  { effectif: "06:45", pauses: "01:00", heures: "07:45", color: "#AFBFE3" },
  { effectif: "07:00", pauses: "01:00", heures: "08:00", color: "#AFBFE3" },
  { effectif: "07:15", pauses: "01:00", heures: "08:15", color: "#AFBFE3" },
  { effectif: "07:30", pauses: "01:00", heures: "08:30", color: "#AFBFE3" },
  { effectif: "07:45", pauses: "01:00", heures: "08:45", color: "#AFBFE3" },
  { effectif: "08:00", pauses: "01:00", heures: "09:00", color: "#AFBFE3" },
  { effectif: "08:15", pauses: "01:00", heures: "09:15", color: "#AFBFE3" },
  { effectif: "08:15", pauses: "01:15", heures: "09:30", color: "#B1D2ED" },
  { effectif: "08:30", pauses: "01:15", heures: "09:45", color: "#B1D2ED" },
  { effectif: "08:45", pauses: "01:15", heures: "10:00", color: "#B1D2ED" },
  { effectif: "09:00", pauses: "01:15", heures: "10:15", color: "#B1D2ED" },
  { effectif: "09:15", pauses: "01:15", heures: "10:30", color: "#B1D2ED" },
  { effectif: "09:30", pauses: "01:15", heures: "10:45", color: "#B1D2ED" },
  { effectif: "09:45", pauses: "01:15", heures: "11:00", color: "#B1D2ED" },
  { effectif: "10:00", pauses: "01:15", heures: "11:15", color: "#B1D2ED" },
  { effectif: "10:15", pauses: "01:15", heures: "11:30", color: "#B1D2ED" },
  { effectif: "10:30", pauses: "01:15", heures: "11:45", color: "#B1D2ED" },
  { effectif: "10:45", pauses: "01:15", heures: "12:00", color: "#B1D2ED" },
  { effectif: "11:00", pauses: "01:15", heures: "12:15", color: "#B1D2ED" },
  { effectif: "11:15", pauses: "01:15", heures: "12:30", color: "#B1D2ED" },
  { effectif: "11:30", pauses: "01:15", heures: "12:45", color: "#B1D2ED" },
  { effectif: "11:45", pauses: "01:15", heures: "13:00", color: "#B1D2ED" },
  { effectif: "12:00", pauses: "01:15", heures: "13:15", color: "#B1D2ED" },
  { effectif: "12:00", pauses: "01:30", heures: "13:30", color: "#CDCDCD" },
];

export default reference;
