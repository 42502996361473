import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import styled from "styled-components";
import reference from "../data/reference";

const StyledTableRow = styled(TableRow)`
  background: ${props => props.color};
`

export function Reference(props) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Heures de travail effectif</TableCell>
            <TableCell>Temps de pauses</TableCell>
            <TableCell>Heures de trafail planifiés</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            reference.map(ref =>
              <StyledTableRow key={ref.heures} color={ref.color}>
                <TableCell>{ref.effectif}</TableCell>
                <TableCell>{ref.pauses}</TableCell>
                <TableCell>{ref.heures}</TableCell>
              </StyledTableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
